body {
  background-color: black;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(rgba(0, 128, 0, 0.10) 2px, transparent 1px), linear-gradient(90deg, rgba(0, 128, 0, 0.10) 1px, transparent 1px);
  background-size: 20px 20px;
}

.grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-image: linear-gradient(rgba(0, 128, 0, 0.10) 1px, transparent 1px), linear-gradient(90deg, rgba(0, 128, 0, 0.10) 1px, transparent 1px);
  background-size: 20px 20px;
}

.terminal {
  background-color: black !important;
  color: green;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
  line-height: 1.5;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 1000px;
  border: 1px solid green;
  border-radius: 5px;
  background-image: url('./logo3.png'); /* Replace 'path/to/your/large-logo.png' with the actual path to your image */
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

.satorian-image {
  width: 300px;
  height: 300px;
  filter: grayscale(100%);  /* Add a black and white filter to the image */
  margin-bottom: 10px;
}

.terminal .logo {
  width: 70px;
  height: auto;
  margin-bottom: 10px;
  opacity: 1; /* Adjust the opacity as needed */
}

.logo {
  width: 70px;
  height: auto;
  margin-bottom: 10px;
  opacity: 0.1; /* Adjust the opacity as needed */
}

.ascii-art {
  max-height: 40vh;
  min-height: 10rem;
  white-space: pre-wrap;
  overflow: hidden;
  margin-bottom: 30px;
}

.output {
  margin-bottom: 10px;
  width: 100%;
}

.options {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 70%;
  vertical-align: top;
}

.file-content {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
  max-height: 300px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

.selected {
  background-color: green;
  color: white; /* Text color changed to white */
}

input {
  background-color: transparent;
  border: none;
  color: green;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  line-height: 3;
  outline: none;
  width: 100%;
  margin-bottom: 20px;
  -webkit-user-modify: read-only-plaintext !important;
}

input:focus {
  outline: none;
}

button {
  background-color: green;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  line-height: 1.5;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: darkgreen;
}

.apply-button {
  background-color: green;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  line-height: 1.5;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.apply-button:hover {
  background-color: darkgreen;
}
